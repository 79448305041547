<template>
	<div class="wrap">
		<van-nav-bar title="账户详情" class="nav-bar">
			<template #left>
				<van-icon name="arrow-left" color="#fff" @click="back()" />
			</template>
		</van-nav-bar>
		<div class="main"  v-for="(v,key) in itemList" :key="key">
			<div class="main-content">
				<div class="left">
					<span>操作类型：<span :style="{color:v.type==1?'#00aa00':'#ffaa00'}">{{v.typeText}}</span></span>
					<span>操作时间：{{v.time}}</span>
				</div>
				<div class="right">
					<span>&yen; {{v.money}}</span>
				</div>
			</div>

			<div>
				<span>操作说明：{{v.desc==''?'无说明':v.desc}}</span>
			</div>
		</div>
	</div>
</template>

<script>
	export default {
		data() {
			return {
				itemList:[],
			};
		},
		methods: {
			back() {
				return window.history.back();
			},
			getUserRechargeList(){
				this.$http({
					method: 'get',
					url: 'getUserRechargeList'
				}).then(res => {
					this.itemList=res.data;
					console.log(res.data)
				})
			}
		},
		created() {
			if (!localStorage.getItem('token')) {
				this.$router.push({
					path: '/Login'
				})
			} else {
				this.getUserRechargeList();
			}
		}
	}
</script>

<style lang='less' scoped>
	.wrap{
	   position: absolute!important;
	   top: 0;
	   left: 0;
	   right: 0;
	   -webkit-overflow-scrolling : touch;
	   background-color: #f2f2f5;
	   height: 100%;
	}
	.wrap .main{
		padding: 15px 15px;
		margin: 30px 10px;
		background: #fff;
		border-radius: 10px;
		line-height: 60px;
		border-radius: 5px;
		box-shadow: rgba(0, 0, 0, 0.1) 0px 10px 50px;
		display: flex;
		flex-direction: column;
	}
	
	.wrap .main .main-content{
		display: flex;
		flex-direction: row;
		justify-content: space-between;
	}
	
	.main-content .left{
		display: flex;
		flex-direction: column;
	}
	
	.main-content .right{
		font-size: 40px;
		margin-right: 10px;
		color: #ffaa00;
	}
</style>