<template>
	<div>
		<div class="container page">
			<van-nav-bar title="余额提现" class="nav-bar">
				<template #left>
					<van-icon name="arrow-left" color="#fff" @click="back()" />
				</template>
				<template #right>
					<span class="nav-right" @click="$router.push({path:'/WithdrawRecord'})">提现记录</span>
				</template>
			</van-nav-bar>
			<div class="main">
				<div class="withdrawMoney">
					<span>提现金额 (元)</span>
					<div class="money">
						<div class="moneyNumber">
							<span class="moneyType">¥</span>
							<van-field v-model="withdraw_money" placeholder="请输入提现的金额" type="number" />
						</div>
						<span class="all" @click="allMoeny()">全部</span>
					</div>
					<div class="information">
						<div class="description">
							<van-popover v-model="showPopover" trigger="click">
								<div class="popover-body" style="padding: 10px;">
									<p>1.单笔限额：最低{{this.withdrawRole.min}}元，最高{{this.withdrawRole.max}}元</p>
									<p>2.提现次数：一天最高提现{{this.withdrawRole.num}}次</p>
									<p>3.到账时间：一般到账时间在2小时左右，最快5分钟内到账</p>
								</div>
								<template #reference @click="withdrawInfo()">
									<van-icon name="info-o" />
									提现说明
								</template>
							</van-popover>
						</div>

						<div class="balance">
							<span>余额：</span>
							<span class="number">{{ this.userInfo.money }}元</span>
						</div>
					</div>
				</div>
				<p>提现将收取您&ensp;{{withdrawRole.paycomm}}%&ensp;的手续费</p>
				<van-button class="withdraw_btn" type="default" @click="doWithdraw()">马上提现</van-button>
			</div>
		</div>

		<!--模拟支付组件-->
		<van-action-sheet :safe-area-inset-bottom="true" v-model="showKey" title="请输入取款密码">
			<div style="height: 400px;">
				<van-password-input :value="opw" :length="4" :gutter="10" info="密码为 4 位数字" :focused="oshowKeyboard"
					@focus="oshowKeyboard = true" />
				<van-number-keyboard v-model="opw" :show="oshowKeyboard" :maxlength="4" @blur="oshowKeyboard = true" />
			</div>
		</van-action-sheet>

		<!--等待遮罩层-->
		<van-overlay :show="showCeng" z-index="10">
			<div>
				<van-loading size="80px" type="spinner" vertical text-size="18px" color="#0094ff">
					{{lodingTitle}}
				</van-loading>
			</div>
		</van-overlay>

		<!--提现消息确认-->
		<van-dialog v-model="showPayCon" title="" width="300px" show-cancel-button @confirm="txOk()">
			<div class="dialogWarp">
				<span class="dialogTitle">提现</span>
				<span class="dialogMoney">&yen;{{withdraw_money}}</span>
				<van-divider class="dialogDivider" />
				<span class="dialogSxf">
					<span class="left">手续费</span>
					<span class="right">&yen;{{Sxf}}</span>
				</span>
				<span class="dialogSxf">
					<span class="left">费率</span>
					<span class="right">{{withdrawRole.paycomm}}%&ensp;(最低&yen;0.1)</span>
				</span>
				<span class="dialogSxf">
					<span class="left">实际到账</span>
					<span class="right">&yen;{{Sjtx}}</span>
				</span>
			</div>
		</van-dialog>
	</div>

</template>

<script>
	import {
		Toast
	} from 'vant';
	export default {
		data() {
			return {
				showPopover: false,
				withdraw_money: "",
				userInfo: {},
				commission: "5",
				withdrawRole: {},
				opw: '',
				Sxf: '',
				Sjtx: '',
				oshowKeyboard: true,
				showKey: false,
				showCeng: false,
				lodingTitle: '',
				showPayCon: false,
			};
		},
		methods: {
			back() {
				return window.history.back();
			},
			getUserInfo() {
				this.$http({
					method: 'get',
					url: 'user_info'
				}).then(res => {
					if (res.code === 200) {
						this.userInfo = res.data;
						this.name = res.data.name;
					} else if (res.code === 401) {
						this.$toast(res.msg);
					}
				})
			},
			getUserWithdrawRole() {
				this.$http({
					method: 'get',
					url: 'user_get_withdraw_role'
				}).then(res => {
					if (res.code === 200) {
						this.withdrawRole = res.data;
						console.log(this.withdrawRole)
					} else if (res.code === 401) {
						this.$toast(res.msg);
					}
				})
			},
			allMoeny() {
				this.withdraw_money = this.userInfo.money;
			},
			doWithdraw() {
				let withdraw_money = Number(this.withdraw_money)
				if (this.userInfo.moneyStatus > 0) {
					this.$toast("账户风控中，暂时无法操作，请联系客服处理！");
					return false;
				}
				if (withdraw_money <= 0) {
					this.$toast("请填写正确的金额");
					return false;
				}

				if (withdraw_money > this.withdrawRole.max) {
					this.$toast("提现金额不得大于" + this.withdrawRole.max);
					return false;
				}

				if (withdraw_money < this.withdrawRole.min) {
					this.$toast("提现金额不得小于" + this.withdrawRole.min);
					return false;
				}

				this.Sxf = (withdraw_money * (Number(this.withdrawRole.paycomm) / 100)).toFixed(2)
				if (this.Sxf < 0.1) this.Sxf = 0.1;
				this.Sjtx = withdraw_money - this.Sxf

				if (this.Sxf >= withdraw_money) {
					this.$toast("提现金额不得少于手续费");
					return false;
				}


				this.showPayCon = true;
			},
			//提现点击确认
			txOk() {
				this.opw = ""
				this.showKey = true;
			},
			withdrawInfo() {
				this.showPopover = true;
			},


			/*----------------------------------------------------*/
		},
		watch: {
			//密码输入检测
			opw(value) {
				if (value.length === 4) {
					this.showKey = false;
					this.showCeng = true;
					this.lodingTitle = "效验密码中"
					//效验支付密码接口
					this.$http({
						method: 'post',
						data: {
							payPassWord: this.opw
						},
						url: 'getPayPassword'
					}).then(res => {
						if (res.code === 200) {
							this.opw = "";
							this.lodingTitle = "正在提交提现申请"
							//支付密码正确，则进行提现操作
							this.$http({
								method: 'post',
								data: {
									money: this.withdraw_money
								},
								url: 'user_set_withdraw'
							}).then(res => {
								if (res.code === 200) {
									this.showCeng = false;
									location.reload()
									this.$toast("申请提现成功");
									this.getUserInfo();
									this.getUserWithdrawRole();
								} else if (res.code === 401) {
									this.showCeng = false;
									this.$toast(res.msg);
								}
							})
						} else if (res.code === 401) {
							this.opw = "";
							this.showCeng = false;
							Toast({
								message: res.msg,
								position: 'top',
							});
						}
					})
				}
			}
		},
		created() {
			if (!localStorage.getItem('token')) {
				this.$router.push({
					path: '/Login'
				})
			} else {
				this.getUserInfo();
				this.getUserWithdrawRole();
			}
		}
	};
</script>

<style lang='less' scoped>
	@import "../../assets/css/base.css";

	.van-cell {
		font-size: 35px;
		line-height: 80px;
	}

	.container p {
		padding: 0 15px;
		margin-top: 15px;
		font-size: 30px;
		color: #dc2037;
	}

	.container .main {
		display: flex;
		flex-direction: column;
		background-color: #f2f2f5;
		height: calc(100% - 50px);
		position: relative;
	}

	.container .main .withdrawMoney {
		display: flex;
		flex-direction: column;
		color: #000;
		padding: 0 20px;
		white-space: nowrap;
		font-size: 35px;
		background-color: #fff;
	}

	.container .main .withdrawMoney span {
		padding: 10px 0;
	}

	.container .main .withdrawMoney .money {
		display: flex;
		flex-direction: row;
		justify-content: space-between;
		border-bottom: 1px solid #f2f2f5;
	}

	.container .main .withdrawMoney .money .moneyNumber {
		font-size: 50px;
		display: flex;
		flex-direction: row;
	}

	.container .main .withdrawMoney span {
		padding: 10px 0;
	}

	.container .main .withdrawMoney .money .all {
		color: #d10404;
	}

	.container .main .withdrawMoney .money .moneyNumber .van-cell {
		font-size: 50px;
		padding: 0 !important;
	}

	.container .main .withdrawMoney .information {
		padding-bottom: 30px;
	}

	.container .main .withdrawMoney .information .description {
		display: flex;
		align-items: center;
		justify-content: flex-end;
		padding: 10px 0;
	}

	.container .main .withdrawMoney span {
		padding: 10px 0;
	}

	.container .main .withdrawMoney .information .balance .number {
		color: #d10404;
	}

	.withdraw_btn {
		margin: 20px 30px 0;
		height: 80px;
		line-height: 1.22667rem;
		border-radius: 50px;
		color: #fff;
		font-size: 30px;
		font-weight: bolder;
		border: none;
		background: linear-gradient(90deg, #e6c3a1, #7e5678);
	}


	/*---------------*/
	::v-deep .van-password-input {
		width: 80%;
		height: 150px;
		margin: 0 auto;
		margin-top: 50px;

	}

	::v-deep .van-password-input__security li {
		font-size: 30px;
		line-height: 30;
		background-color: #ebedf0;
	}

	::v-deep .van-password-input__security {
		height: 130px;
	}

	::v-deep .van-password-input .van-password-input__security .van-password-input__item {
		height: 100%;
		border: 0;
		text-align: center;
		border-radius: 30px;
	}

	.van-password-input__security i {
		width: 25px;
		height: 25px;
	}

	::v-deep .van-key {
		height: 100px;
		font-size: 55px;
		line-height: 20px;
		border-radius: 20px;
	}

	::v-deep .van-number-keyboard {
		z-index: 100;
		width: 100%;
		padding-bottom: 30px;
		background-color: #f2f3f5;
	}

	::v-deep .van-key__collapse-icon {
		width: 50px;
		height: 50px;
	}

	::v-deep .van-key__delete-icon {
		width: 50px;
		height: 50px;
	}

	::v-deep .van-action-sheet__header {
		margin-top: 40px;
		font-size: 5.133vw;
	}

	::v-deep .van-action-sheet__close {
		margin-top: 20px;
		margin-right: 20px;
		font-size: 30px;
	}

	::v-deep .van-password-input__info {
		margin-top: 30px;
		font-size: 30px;
	}

	::v-deep .van-loading--vertical {
		position: absolute;
		top: 50%;
		left: 50%;
		transform: translate(-50%, -50%);
	}


	/*弹窗样式*/
	.dialogWarp {
		display: flex;
		flex-direction: column;
		align-items: center;

	}

	.dialogWarp .dialogTitle {
		font-size: 25px;
		margin-top: 30px;
		font-weight: 400;
		color: #515151;
	}

	.dialogWarp .dialogMoney {
		font-size: 70px;
		margin-top: 30px;
	}

	.dialogWarp .dialogDivider {
		border-color: #bababa;
		width: 85%;
		margin-top: 30px;
	}


	.dialogWarp .dialogSxf {
		display: flex;
		align-items: center;
		justify-content: space-between;
		flex-direction: row;
		margin-top: 10px;
		font-size: 25px;
		width: 85%;
		color: #737373;
	}

	.dialogWarp .left {
		align-self: flex-start;
		color: #323232;
	}

	.dialogWarp .right {
		align-self: flex-end;
	}

	::v-deep .van-dialog__cancel {
		font-size: 28px;
		height: 70px;
	}

	::v-deep .van-dialog__confirm {
		font-size: 28px;
		height: 70px;
	}

	::v-deep .van-dialog__footer {
		margin-top: 20px;
	}
</style>