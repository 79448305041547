<template>
	<div class="convention-hall page">
		<div class="banner">
			<swiper class="banner_swiper" :options="bannerSwiperOption">
				<swiper-slide v-for="(v,key) in banners" :key="key">
					<van-image class="banner_img" round :src="v.url">
						<template v-slot:loading>
							<van-loading type="circular" />
						</template>
					</van-image>
				</swiper-slide>
			</swiper>
		</div>
		<div>
			<van-notice-bar class="notice-swipe" left-icon="bullhorn-o" background="#ffffff" color="#7e5678"
				:text="this.notice" />
			<div class="linear-gradient"></div>
		</div>
		<div class="convention-item">
			<div class="left">
				<van-tabs @change="onChange" v-model="activeKey">
					<van-tab title="全部"></van-tab>
					<van-tab v-for="(v,key) in lotteryitem" :key="key" :title="v.name" :swipe-threshold="2" />
				</van-tabs>
			</div>
			<div class="right">
				<van-pull-refresh :border="false" class="list-wrapper" v-model="isLoading" @refresh="onRefresh">
					<van-grid :column-num="3">
						<van-grid-item @click="toLottery(v.key,v.id,v.status)" v-for="(v,key) in gameitem" :key="key">
							<van-image class="game_item_img" :src="v.ico">
								<template v-slot:loading>
									<van-loading type="circular" />
								</template>
							</van-image>
							<span>{{v.name}}</span>
							<span>{{v.desc}}</span>
						</van-grid-item>
					</van-grid>
				</van-pull-refresh>
			</div>
		</div>

	</div>
</template>

<script>
	import {
		Toast
	} from 'vant';
	export default {
		data() {
			return {
				notice: "获取中......",
				gameitem: [{}, {}, {}, {}],
				lotteryitem: [{}, {}, {}, {}],
				isLoading: false,
				activeKey: 0,
				banners: [{}],
				bannerSwiperOption: {
					effect: 'slide',
					grabCursor: true,
					centeredSlides: true,
					slidesPerView: 'auto',
					speed: 800,
					autoplay: true,
					coverflowEffect: {
						rotate: 50,
						stretch: 10,
						depth: 100,
						modifier: 1,
						slideShadows: true
					}
				}
			};
		},
		methods: {
			onRefresh() {
				setTimeout(() => {
					Toast('刷新成功');
					this.isLoading = false;
				}, 500);
			},
			toLottery(key, id, status) {
				if (!localStorage.getItem('token')) {
					this.$router.push({
						path: '/Login'
					})
				} else {
					this.$http({
						method: 'get',
						url: 'getLotteryStatus',
						data:{
							id:id
						}
					}).then(res => {
						console.log(res.data)
						if (res.data.status == 0) {
							this.$toast("当前账户等级不足，无法进入!");
							return false;
						} else {
							this.$router.push({
								path: '/Lottery?key=' + key + "&id=" + id
							})
						}
					})
				}
			},
			getGameItem() {
				this.$http({
					method: 'get',
					url: 'lottery_list'
				}).then(res => {
					this.gameitem = res.data;
				})
			},
			onChange(index) {
				this.$http({
					method: 'get',
					data: {
						class: index
					},
					url: 'lottery_list'
				}).then(res => {
					this.gameitem = res.data;
				})
			},
			getLotteryItem() {
				this.$http({
					method: 'get',
					url: 'lottery_class'
				}).then(res => {
					this.lotteryitem = res.data;
				})
			},
			getBasicConfig() {
				this.$http({
					method: 'get',
					url: 'sys_config'
				}).then(res => {
					this.basicData = res.data;
					this.getNotice(this.basicData); //获取公告
					this.getBanner(this.basicData); //获取banner
				})

			},
			getNotice(data) {
				this.notice = data.notice;
			},
			getBanner(data) {
				this.banners = data.banners;
			}
		},
		created() {
			this.getGameItem(); //获取首页游戏列表
			this.getLotteryItem();
			this.getBasicConfig();
		}
	};
</script>

<style lang='less' scoped>
	@notice-bar-size: 30px;
	@movie-list-item-bottom-size: 25px;

	.page {
		position: absolute !important;
		top: 0;
		left: 0;
		right: 0;
		background-color: #f2f2f5;
	}

	.nav-bar {
		background: linear-gradient(90deg, #7e5678, #e6c3a1);
		height: 100px;

	}

	.van-nav-bar {
		line-height: 50px;
	}

	::v-deep .van-nav-bar__title {
		max-width: 60%;
		margin: 0 auto;
		color: #ffffff;
		font-size: 35px;
	}

	::v-deep .van-nav-bar__content {
		height: 100px;
	}

	.van-sidebar {
		width: 180px;
	}

	.van-sidebar-item--select::before {
		left: 10px;
		height: 44%;
		background-color: #7e5678;
		border-radius: 5px;
		width: 10px;
	}

	.van-sidebar-item--select {
		color: #7e5678;
		font-size: 35px;
		text-align: center;
	}

	.van-sidebar-item {
		font-size: 35px;
		text-align: center;
		padding: 50px;
		background-color: #ffffff;
	}

	.van-sidebar-item--select,
	.van-sidebar-item--select:active {
		background-color: #f2f2f5;
		;
	}

	.convention-item {
		display: flex;
		flex-direction: column;
		align-items: center;
		height: calc(100% - 15px);
	}

	.convention-hall {
		display: flex;
		flex-direction: column;
		bottom: 20px;
		background: #f2f2f5;
	}

	.convention-item .left {
		width: 100%;
		height: 80px;
		/*height: 100%;*/
		background-color: #fff;
		-webkit-overflow-scrolling: touch;
	}

	.convention-item .right {
		width: 100%;
		/*height: 100%;*/
		flex: 1;
		background-color: #f2f2f5;
		-webkit-overflow-scrolling: touch;
	}

	.convention-item .right .list-wrapper {
		padding: 20px 20px;
		height: calc(100% - 110px);
		overflow-y: auto;
	}

	.convention-item .right .list-wrapper .game_item_img {
		width: 200px;
		height: 200px;
	}

	.convention-item .right .list-wrapper span {
		margin-top: 10px;
		font-size: 30px;
		color: #000;
	}

	.convention-item .right .list-wrapper span:last-child {
		margin-top: 10px;
		font-size: 24px;
		color: #000;
	}

	.van-grid-item {
		padding: 10px;

	}

	::v-deep .van-grid-item__content--center {
		border-radius: 15px;
	}

	::v-deep .van-image__img {
		border-radius: 40px;
	}

	::v-deep .van-pull-refresh__track .van-pull-refresh__head * {
		color: #000000;
		font-size: 35px;
	}


	/*下面是自定义的样式*/
	.banner {
		width: 100%;
		margin-top: 0 auto;
	}

	.banner_swiper {
		height: 100%;
		width: 100%;

		.swiper-slide {
			/* border-radius: 10px; */
			display: flex;
			justify-content: center;
			align-items: center;
			width: 100%;
			height: 360px;
			text-align: center;
			font-weight: bold;
			font-size: 20px;
			background-color: #ffffff;
			background-position: center;
			background-size: cover;
			color: #ffffff;
		}
	}

	.banner_img {
		border-radius: 0px;
		width: 100%;
		height: 100%;
	}

	.notice-swipe {
		/* width: calc(100% - 50px); */
		height: 85px;
		font-size: @notice-bar-size;
	}

	.linear-gradient {
		width: 100%;
		height: 2px;
		background: linear-gradient(to right, rgba(126, 86, 120, 0), rgb(230, 195, 161), rgba(126, 86, 120, 0));
	}

	:global(.van-notice-bar__left-icon) {
		font-size: 4.133vw;
	}

	:global(.van-tabs--line .van-tabs__wrap) {
		height: 80px;
	}

	:global(.van-tab) {
		font-size: 3.6vw;
		line-height: normal;
		margin-right: 2.5vw;
	}

	:global(.van-tabs__line) {
		width: 10.333vw;
		height: 0.7vw;
	}
</style>