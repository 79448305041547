<template>
	<div class="wrap">
		<van-nav-bar title="彩金礼包" class="nav-bar">
			<template #left>
				<van-icon name="arrow-left" color="#fff" @click="back()" />
			</template>
		</van-nav-bar>
		<van-empty v-if="itemList.length === 0" description="数据为空！" />
		<div class="main" v-for="(v,key) in itemList" :key="key">
			<span class="time">{{v.time}}</span>
			<div class="main-warp">
				<span class="left">系统</span>
				<span class="right" @click="openBox(v)">
					<span class="right-top">
						<span>
							<van-icon v-if="v.status == 0" class="arrow-icon" size="50px" name="gold-coin" />
							<van-icon v-else class="arrow-icon openTitle" name="passed" size="50px"/>
						</span>
						<span class="right-top-right">
							<span :class="{openTitle:v.status == 1}" class="money">{{v.status==0?'彩金红包':'&yen;'+v.money}}</span>
							<span :class="{openTitle:v.status == 1}" class="text">{{v.status==0?'待领取':'已领取'}}</span>
						</span>
					</span>
					<span><van-divider class="line" /></span>
					<span :class="{openTitle:v.status == 1}" class="desc">礼包说明：{{v.desc==''?'无':v.desc}}</span>
				</span>
			</div>
		</div>

		<van-overlay :show="show" @click='beforeClose()'>
			<div>
				<div class="bg-red">
					<div class="redbtm">
						<span class="topgxfc">{{info.time}}</span>
						<span class="topgxfc1">礼包说明：{{info.desc==''?'无':info.desc}}</span>
					</div>
					<div v-if="open==false" class="redmid open-anim" :class="{ animated: isAnimated }"
						@click.stop="loadFlash()">
						<div class="midTxt">開</div>
					</div>
					<div v-if="open==true" class="showMon">
						<div>&yen; {{info.money}}<span>元</span></div>
						<div style="font-size: 15px;">领取成功，已存入钱包</div>
					</div>
				</div>
			</div>
		</van-overlay>

		<audio ref="audio" src="/zfb.aac"></audio>
	</div>
</template>

<script>
	export default {
		data() {
			return {
				itemList: [],
				show: false,
				isAnimated: false,
				open: true,
				info: [],
			};
		},
		methods: {
			back() {
				return window.history.back();
			},
			getUserRechargeMoney() {
				this.$http({
					method: 'get',
					url: 'getUserRechargeMoney'
				}).then(res => {
					this.itemList = res.data;
				})
			},
			//开红包按钮被单击
			loadFlash() {
				this.isAnimated = true;
				let taht = this;
				this.$http({
					method: 'post',
					url: 'setUserRechargeMoney',
					data: {
						id: this.info.id
					}
				}).then(res => {
					console.log(res)
					if (res.code === 200) {
						taht.isAnimated = false;
						taht.open = true;
						const audioPlayer=this.$refs.audio;
						audioPlayer.play();
					} else {
						taht.isAnimated = false;
						this.beforeClose();
						this.$toast(res.msg);
					}
					this.getUserRechargeMoney();
				})
			},
			openBox(event) {
				if(event.status==1){
					this.$toast('已领取，请勿重复领取');
					return false;
				}
				this.isAnimated = false;
				this.open = false
				this.show = true
				this.info = event;
			},
			beforeClose() {
				if (this.isAnimated == true) return false;
				this.show = false
				const taht = this;
				setTimeout(function() {
					that.info = [];
					taht.isAnimated = false;
					taht.open = false
				}, 500);

			}
		},
		created() {
			if (!localStorage.getItem('token')) {
				this.$router.push({
					path: '/Login'
				})
			} else {
				this.getUserRechargeMoney();
			}
		}
	}
</script>

<style lang='less' scoped>
	.wrap {
		position: absolute !important;
		top: 0;
		left: 0;
		right: 0;
		-webkit-overflow-scrolling: touch;
		background-color: #f2f2f5;
		height: 100%;
	}

	.wrap .main {
		padding-left: 15px;
		padding-right: 15px;
		margin-top: 25px;
		border-radius: 10px;
		line-height: 60px;
		display: flex;
		flex-direction: column;
		align-items: center;
	}

	.main-warp {
		display: flex;
		flex-direction: row;
		width: 100%;
		margin-top: 15px;
	}

	.main .left {
		display: flex;
		justify-content: center;
		align-items: center;
		background-color: #aaaaff;
		color: #fff;
		width: 90px;
		height: 90px;
		border-radius: 10px;
		font-size: 25px;
	}

	.main .right {
		flex: 1;
		margin-left: 20px;
		background: #ffaa00;
		border-radius: 20px;
		display: flex;
		justify-content: center;
		flex-direction: column;


	}

	.right-top {
		display: flex;
		flex-direction: row;
		align-items: center;
	}

	.arrow-icon {
		margin-top: 20px;
		margin-left: 20px;
		color: #ffffff;
	}

	.right-top-right {
		display: flex;
		flex-direction: column;
		margin-left: 50px;
		padding-top: 15px;
		color: #ffffff;
	}

	.money {
		font-size: 40px;
	}

	.text {
		font-size: 30px;
		margin-top: -15px;
	}

	.line {
		color: #ec9600;
		margin-left: 10px;
		margin-right: 10px;
	}

	.desc {
		margin-left: 10px;
		margin-right: 10px;
		margin-bottom: 10px;
		font-size: 25px;
		color: #ffffff;
	}

	.time {
		background-color: rgba(223, 223, 223, 0.3);
		border-radius: 20px;
		padding: 0px 15px 0px 15px;
		font-size: 25px;
		color: #5a5a5a;
	}





	.bg-red {
		position: relative;
		color: #fff;
		height: 25em;
		margin: auto;
		width: 18em;
		overflow: hidden;
		border-radius: 1em;
		background: #c40b00;
		transform: translateY(-50%);
		margin-top: 25em;
	}

	.redbtm {
		position: relative;
		width: 200%;
		height: 300px;
		border-radius: 0 0 50% 50%;
		left: -51%;
		background: #F26057;
		box-shadow: 1px 1px 10px rgba(0, 0, 0, 0.5);
	}

	.redmid {
		position: relative;
		top: -80px;
		width: 180px;
		height: 180px;
		margin: auto;
		border-radius: 50%;
		background: #ebcc9d;
		box-shadow: 1px 2px 5px rgba(0, 0, 0, 0.5);
	}

	.showMon {
		display: flex;
		flex-direction: column;
		justify-content: center;
		align-self: center;
		position: relative;
		top: 120px;
		width: 100%;
		line-height: 80px;
		color: #DCCD79;
		font-size: 80px;
		text-align: center;
		height: 180px;
		font-family: 'Lucida Sans', 'Lucida Sans Regular', 'Lucida Grande', 'Lucida Sans Unicode', Geneva, Verdana, sans-serif;
	}

	.showMon span {
		font-size: 20px;
	}

	.midTxt {
		text-align: center;
		color: #333;
		font-size: 50px;
		line-height: 180px;
	}

	@keyframes open {
		0% {
			transform: rotateY(0deg);
		}

		100% {
			transform: rotateY(360deg);
		}
	}

	.topgxfc {
		display: block;
		width: 100%;
		text-align: center;
		color: #DCCD79;
		line-height: 140px;
		font-size: 30px;
	}

	.topgxfc1 {
		display: block;
		width: 100%;
		text-align: center;
		color: #DCCD79;
		line-height: 30px;
		font-size: 20px;
	}

	::v-deep .van-overlay {
		display: flex;
		justify-content: center;
		align-items: center;
	}

	/*动态绑定的css*/

	.animated {
		animation: 3s open;
		animation-iteration-count: infinite;
	}
	
	
	.openTitle{
		color: #dcdcdc;
	}
</style>